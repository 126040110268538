<template>
  <div class="map-container">
    <div class="map-viewer">
      <div ref="mapContainer" class="map-content"></div>

      <slot />
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import L from "leaflet";
L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
export default {
  props: {
    initOptions: {
      type: Object,
      default: () => ({
        center: [105.85121154785156, 21.02682813712425],
        zoom: 2.0538984656017196,
        maxZoom: 22,
        attributionControl: false,
        zoomControl: false,
      }),
    },
    gestureHandling: Boolean,
  },

  data() {
    return {
      map: null,
    };
  },
  created() {},

  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },

  destroyed() {
    this.destroy();
  },

  methods: {
    init() {
      this.map = L.map(this.$refs.mapContainer, {
        ...this.initOptions,
        gestureHandling: this.gestureHandling,
        gestureHandlingText: {
          touch: "Sử dụng 2 ngón để di chuyển bản đồ",
          scroll: "Sử dụng ctrl + scroll để phóng to",
          scrollMac: "Sử dụng \u2318 + scroll để phóng to",
        },
      }).once("load", (e) => {
        this.loaded = true;
        this.$emit("map-loaded", this.map);
      });
      this.map.setView(this.initOptions.center, 13);
      this.map.whenReady((e) => {
        this.loaded = true;
        this.$emit("map-loaded", this.map);
      });
    },

    destroy() {
      this.map.remove();
      this.map = null;
    },
  },
};
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-flow: column;
  min-height: 0;
  min-width: 0;
  z-index: 0;
}

.map-viewer {
  position: relative;
  flex: 1;
  min-height: 0;
  min-width: 0;
  z-index: 0;
}

.map-content {
  width: 100%;
  height: 100%;
}
</style>
