import ModuleContainer from "@components/map-container/ModuleContainer.vue";

export default {
  components: {
    ModuleContainer,
  },

  props: {
    position: {
      type: String,
      default: "bottom-right",
      validator(value) {
        return (
          ["top-left", "top-right", "bottom-left", "bottom-right"].indexOf(
            value
          ) !== -1
        );
      },
    },
  },

  data() {
    return {
      loaded: false,
    };
  },

  inject: ["getMap"],

  created() {
    this.$once("module-loaded", () => {
      this.loaded = true;
    });

    this.$on("module-failed", () => {
      this.loaded = false;
    });

    this.getMap(async (map) => {
      this.map = map.getMap();

      // Revoke event
      if (this.onInit instanceof Function) {
        const command = this.onInit();

        if (command instanceof Promise) {
          try {
            await command;
          } catch (error) {
            console.error(error);
            this.$emit("module-failed");
            return;
          }
        }
      }

      this.$emit("module-loaded");
    });
  },

  beforeDestroy() {
    if (!this.map) return;

    if (this.onDestroy instanceof Function) {
      this.onDestroy();
    }
    this.removeControl();
    this.map = null;
  },

  methods: {
    resize() {
      if (this.map) {
        this.$nextTick(() => {
          this.map.resize();
        });
      }
    },
    addControl(controlInstance) {
      this.control = controlInstance;

      this.map.addControl(this.control, this.position);
    },

    removeControl() {
      if (!this.control || !this.map) return;

      try {
        this.map.removeControl(this.control);
      } catch (error) {
        console.error(error);
      }

      this.control = null;
    },

    getControl() {
      if (this.loaded) {
        return Promise.resolve(this.control);
      } else {
        return new Promise((resolve, reject) => {
          this.$once("module-loaded", () => {
            resolve(this.control);
          });

          this.$once("module-failed", reject);
        });
      }
    },
  },
};
