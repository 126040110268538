const dataStore = new Map();
export const cache = {
  add: (key, value) => {
    if (dataStore.has(key)) {
      throw new Error(`Cache already contains '${key}'`);
    }
    dataStore.set(key, {
      value,
      count: 1,
    });
  },
  fetch: (key) => {
    const cacheObj = dataStore.get(key);
    if (cacheObj) {
      cacheObj.count++;
      return cacheObj.value;
    }
  },
  release: (key) => {
    const cacheObj = dataStore.get(key);
    if (!cacheObj) {
      throw new Error(`No such key in cache '${key}'`);
    }
    cacheObj.count--;

    if (cacheObj.count === 0) {
      dataStore.delete(key);
    }
  },
};

export function loadImage(url, { transformRequest }) {
  const fetchObj = { ...transformRequest(url) };

  if (fetchObj.headers) {
    return loadImageViaFetch(url, removeUrl(fetchObj));
  } else {
    return loadImageViaTag(url);
  }
}

export function loadJson(url, { transformRequest }) {
  const fetchObj = { ...transformRequest(url) };
  return fetch(fetchObj.url, removeUrl(fetchObj)).then((res) => res.json());
}

function loadImageViaTag(url) {
  let cancelled = false;
  const promise = new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      if (!cancelled) resolve(img);
    };
    img.onerror = (e) => {
      if (!cancelled) reject(e);
    };
    img.src = url;
  });
  promise.cancel = () => {
    cancelled = true;
  };
  return promise;
}

function removeUrl(obj) {
  obj = { ...obj };
  delete obj["url"];
  return obj;
}

function loadImageViaFetch(url, init) {
  return fetch(url, init)
    .then((res) => res.blob())
    .then((blob) => URL.createObjectURL(blob))
    .then((url) => loadImageViaTag(url));
}
